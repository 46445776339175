import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Autocomplete } from '@mui/material';
import { TraerRadicadoFL16, Alert, getIDWorkmanager } from '../FunctionLogin';
import Swal from 'sweetalert';

export default function CambioRadicado({onChange}) {
    const [nit, setNit] = useState('');
    const [loading, setLoading] = useState(false);
    const [VectorT, setVector] = React.useState([]);
    const [cambiar, setCambiar] = React.useState();

    const handleNitChange = (event) => {
        setNit(event.target.value);
    };


    const handleEncryptClick = () => {
        localStorage.setItem('RadicadoFL16', nit.split('-')[2]);
        onChange(nit.split('-')[2])
        Swal('Correcto', 'Radicado en memoria: '+nit.split('-')[2], 'success');
    };

    React.useEffect(() => {
    }, [VectorT])

    return (
        <>
            <Container maxWidth="sm" style={{ padding: '50px' }}>
                <Card sx={{ maxWidth: 600 }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                                     F-CS-CP-34 CONSECUTIVOS DE IMPO / EXPO
                        </Typography>
                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '650px' }}>

                            <Autocomplete disablePortal style={{ left: "100px", width: "100%" }}
                                onInputChange={(event, newInputValue) => {
                                    if (newInputValue.length >= 4) {
                                        TraerRadicadoFL16(newInputValue).then((result) => {
                                            setVector(result)
                                            setCambiar(Math.random().toString().slice(2, 6))
                                            setNit(newInputValue)
                                            //setNit(newInputValue = newInputValue.substring(0, newInputValue.indexOf("-")))
                                        })
                                    }
                                }}
                                id="controllable-states-demo"
                                options={VectorT.filter(item => !item.Radicado)}
                                label={VectorT.filter(item => !item.Radicado)}
                                sx={{ width: 480 }}
                                renderInput={(params) => <TextField autoComplete='false' {...params} label="radicado" />} />
                            <Button variant="contained" onClick={handleEncryptClick} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Consultar'}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}
