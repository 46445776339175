import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Typography,
    Button,
    Checkbox,
    FormControlLabel,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    useMediaQuery,
    useTheme,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert';
import Swal2 from 'sweetalert2';
import { useRef } from 'react';
import ReactSignatureCanvas from "react-signature-canvas";
import CambioRadicado from "./busquedaDeRadicado"

import { TraerRadicadoFL16, TraerDatosFL16, ModificarFL16, InsertFormFL16, EnviarCorreo, EnviarCorreoPersonalizado } from "../FunctionLogin"
import LZString from 'lz-string';

const InspeccionFormulario = () => {

    const initialFormData = {
        marcaContenedor: '',
        numeroContenedor: '',
        placaVehiculo: '',
        numeroTrailer: '',
        conductor: '',
        cedula: '',
        numeroCelular: '',
        fechaARL: '',
        arlDia: '',
        arlMes: '',
        arlAno: '',
        empresaTransportadora: '',
        vehiculo: {
            tarjetaPropiedad: '',
            soat: '',
            licenciaConduccion: '',
            manifiestoCarga: '',
            carpado: '',
            contenedor: '',
            furgon: '',
            tractoCamion: '',
        },
        equipoCarretera: {
            gato: false,
            cruceta: false,
            triangulos: false,
            botiquin: false,
            tacos: false,
            extintor: false,
            linterna: false,
        },
        inspeccionVisual: {
            cabina: false,
            cajaHerramientas: false,
            tanqueAgua: false,
            llantaRepuesto: false,
            pisoNivelado: false,
            puertasCompartimientos: false,
            cajaBateria: false,
            lateralesCarroceria: false,
            compartimientosInterior: false,
            chasisQuintaRueda: false,
            datosCoinciden: false,
        },
        inspeccionCondiciones: {
            plagas: false,
            materialExtrano: false,
            humedadGrasa: false,
            materialesDiferentes: false,
            compartimientoConduccion: false,
            compartimientoCarga: false,
            normasPlanta: false,
            presentacionPersonal: false,
            estadoVehiculo: false,
            bisagras: false,
            ausenciaPisos: false,
            ausenciaRemaches: false,
        },
        PaisOrigen: "",
        operacion: "",
        horaLlegada: "",
        inspeccionCondiciones31: {
            numeroContenedor: "",
            adhesivoNuevo: "",
            marcasSoldadura: "",
            pinturaNueva: "",
            ondulaciones: "",
            vigasSonido: "",
            canalesTapas: "",
            remachesTuercas: "",
            areasGolpes: "",
            olores: "",
            cargaSolida: "",
            transport: "",
        },
        inspeccionCondiciones32: {
            desnivelado: "",
            nivelVigasInferiores: "",
            nivelVigasInferiores: "",
            reparacionesMalosAcabados: "",
            pisoExteriorVigas: "",
            tunelPisoExterior: "",
            cambiosChapasyTravesanos: "",
        },
        inspeccionCondiciones33: {
            marcasSoldadura: "",
            pinturaNueva: "",
            sonidoOndulaciones: "",
            longitudInterna: "",
        },
        inspeccionCondiciones34: {
            soportesOcultos: "",
            orificiosVentilacion: "",
            techoDesnivelado: "",
            marcasSoldaduraTecho: "",
            pinturaNuevaTecho: "",
        },
        cantidades: {
            coincidenCantidades: '',
            coincidenCantidadesv2: '',
            coincidenPeso: '',
        },
        inspeccionCondiciones36: {
            alegatoriaDeCajas: '',
            alegatoriaDeGuacal: '',
        },
        identificacion: '',
        inspeccionCondiciones4: {
            hermeticas: '',
            sellos: '',
            identificacion: '',
            selloproveedor: '',
        }
        , imagenesImportacion: {
            fotoConductorPlaca: null,
            fotoCabezotePlaca: null,
            fotoNumeroTrailer: null,
            fotoSoatLicencia: null,
            fotoSelloPrecinto: null,
            fotoNumeroContenedor: null,
            fotoPiezasVehiculo: null,
            fotoFuncionariosDescargue: null,
            fotoInteriorVehiculoDescargado: null,
            fotoProcesoDescargue: null,
            fotoDestruccionPrecintos: null,
        },
        imagenesExportacion: {
            fotoConductorPlaca: null,
            fotoCabezotePlaca: null,
            fotoNumeroTrailer: null,
            fotoContenedorVehiculo: null,
            fotoInteriorContenedor: null,
            fotoSoatLicencia: null,
            fotoSelloPrecintoAntes: null,
            fotoPiezasVehiculoAntes: null,
            fotoFuncionariosCargue: null,
            fotosProcesoCargue: null,
            fotosVehiculoCerrado: null,
            fotoNumeroSelloPrecintos: null,
            registroFilmicoPerforacionGuacales: null,
            destruccionPrecintos: null,
        },
        tipoImportacion: {
            importacion: ''
        },
        Personal1: "",
        Personal2: "",
        Personal3: "",
        Personal4: "",
        Personal5: "",
        firmas: {
            firmaConductor: null,
            firmaResponsableInspeccion: null,
        },
        Peso35: "",
        unidadesRecibidas: "",
        observaciones36: "",
        observaciones35: "",
        observaciones22: "",
        constanciatransporte: "",
        novedades: '',
        Guardado: {
            habilitado: false
        }
    }
    const [formData, setFormData] = useState(initialFormData);
    const sigPadConductor = useRef({});
    const sigPadResponsableInspeccion = useRef({});
    const [loading, setLoading] = useState(true);
    const [Validado, setvalidado] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const [valorRadicado, setValorRadicado] = useState(null);


    useEffect(() => {
        console.log(localStorage.RadicadoFL16)
        if (localStorage.RadicadoFL16 == null) {
            setFormData(initialFormData)
            setLoading(false);
        } else {
            TraerDatosFL16(localStorage.RadicadoFL16).then((result) => {

                if (result == "") {
                    setFormData(initialFormData)
                    setLoading(false);
                } else {

                    const inspeccionFormularioData = JSON.parse(result);
                    const { firmaConductor, firmaResponsableInspeccion } = inspeccionFormularioData.firmas;
                    // Retrasar la carga para asegurar que el canvas esté montado
                    setTimeout(() => {
                        if (firmaConductor && sigPadConductor.current?.fromDataURL) {
                            sigPadConductor.current.fromDataURL(firmaConductor);
                        }

                        if (firmaResponsableInspeccion && sigPadResponsableInspeccion.current?.fromDataURL) {
                            sigPadResponsableInspeccion.current.fromDataURL(firmaResponsableInspeccion);
                        }
                    }, 100); // 100ms delay, puedes ajustarlo según sea necesario

                    if (JSON.parse(result)) {
                        setFormData(inspeccionFormularioData);
                    }
                    setLoading(false);
                }
            })
        }
    }, [localStorage.RadicadoFL16]);


    const handleSave = () => {
        const datos = JSON.stringify(formData).replace(/(\r\n|\n|\r)/gm, '');
        ModificarFL16(localStorage.RadicadoFL16, datos).then((result) => {
            if (result) {
                Swal(`Ingreso la información con exito`, "GRACIAS!", "success");
            } else {
                Swal("No se inserto el RADICADO del registro!", "Valide la información!", "warning");
            }
        })
    }





    const saveFirma = (ref, key) => {
        const canvas = ref.current.getCanvas();
        const base64Data = canvas.toDataURL();

        setFormData(prevFormData => ({
            ...prevFormData,
            firmas: {
                ...prevFormData.firmas,
                [key]: base64Data,
            }
        }));

        Swal("Gracias", "Firma ingresada con éxito", "success");
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };



    const handleDateChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value, // Mantén el valor de la fecha como está
        });
    };

    const handleTimeChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value, // Mantén el valor del tiempo como está
        });
    };

    const handleCheckboxChange = (section, name) => (event) => {
        setFormData({
            ...formData,
            [section]: {
                ...formData[section],
                [name]: event.target.checked,
            },
        });
    };

    const handleComboboxChange = (section, name) => (event) => {
        setFormData({
            ...formData,
            [section]: {
                ...formData[section],
                [name]: event.target.value,
            },
        });
    };



    const handleComboboxChangeEspecial = (section, name, tipo) => (event) => {
        const updatedFormData = {
            ...formData,
            [section]: {
                ...formData[section],
                [name]: event.target.value,
            },
        };

        setFormData(updatedFormData);
        if (tipo == "tipo2") {
            if (event.target.value == "no") {
                Swal(`Se notificó porque los pesos/cantidades no coinciden `, "GRACIAS!", "success");
                const datos = JSON.stringify(updatedFormData).replace(/(\r\n|\n|\r)/gm, '');
                var body = "<table class=gmail-baZpAe data-gmail-template=text-slot cellpadding=7px width=100% style=border-collapse: collapse; border-spacing: 0px;><tbody><tr><td width=100% align=center style=word-break: break-word;><div id=gmail-h.5jg9b6jhdvjq class=gmail-Ap4VC gmail-yMxPgf gmail-aP9Z7e style=margin-bottom: 0px;></div><h1 id=gmail-h.5jg9b6jhdvjq_l dir=ltr class=gmail-zfr3Q gmail-duRjpb gmail-CDt4Ke style=text-align: left; margin: 0px 0pt; padding-top: 0px; padding-bottom: 0px; font-size: 34pt; font-family: Tahoma; font-weight: 700; font-style: normal; font-variant: normal; vertical-align: baseline; text-decoration: none; display: block; line-height: 1.2; padding-left: 0pt; text-indent: 0pt; color: rgb(182, 56, 64); outline: none;><span class=gmail-C9DxTc>El Radicado&nbsp; {Radicado} se notifica porque los pesos/cantidades no coinciden.</span></h1></td></tr></tbody></table>"
                var body = body.replace("{Radicado}", localStorage.RadicadoFL16)
                EnviarCorreoPersonalizado("sebastian.gomez@talsa.com.co", "Solicitud Formato FL16: " + localStorage.RadicadoFL16, body)
            }
        } else {
            if (event.target.value == "no") {
                const datos = JSON.stringify(updatedFormData).replace(/(\r\n|\n|\r)/gm, '');
                ModificarFL16(localStorage.RadicadoFL16, datos).then((result) => {
                    if (result) {
                        Swal2.fire({
                            title: 'Se notificó porque los datos no coinciden con los informados por el proveedor de transporte',
                            input: 'textarea',
                            inputLabel: 'Ingrese un comentario para notificar la novedad',
                            inputPlaceholder: 'Escribe tu comentario aquí...',
                            inputAttributes: {
                                'aria-label': 'Escribe tu comentario aquí'
                            },
                            showCancelButton: false,
                            confirmButtonText: 'Notificar',
                            cancelButtonText: 'Cancelar',
                            icon: 'info',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // Aquí puedes manejar el comentario ingresado
                                const comentario = result.value;
                                var body = "<table class=gmail-baZpAe data-gmail-template=text-slot cellpadding=7px width=100% style=border-collapse: collapse; border-spacing: 0px;><tbody><tr><td width=100% align=center style=word-break: break-word;><div id=gmail-h.5jg9b6jhdvjq class=gmail-Ap4VC gmail-yMxPgf gmail-aP9Z7e style=margin-bottom: 0px;></div><h1 id=gmail-h.5jg9b6jhdvjq_l dir=ltr class=gmail-zfr3Q gmail-duRjpb gmail-CDt4Ke style=text-align: left; margin: 0px 0pt; padding-top: 0px; padding-bottom: 0px; font-size: 34pt; font-family: Tahoma; font-weight: 700; font-style: normal; font-variant: normal; vertical-align: baseline; text-decoration: none; display: block; line-height: 1.2; padding-left: 0pt; text-indent: 0pt; color: rgb(182, 56, 64); outline: none;><span class=gmail-C9DxTc>El Radicado&nbsp; {Radicado} se envio a aprobación. {comentario} </span></h1></td></tr></tbody></table>"
                                var body = body.replace("{Radicado}", localStorage.RadicadoFL16)
                                var body = body.replace("{comentario}", comentario)
                                EnviarCorreoPersonalizado("sebastian.gomez@talsa.com.co", "Solicitud Formato FL16: " + localStorage.RadicadoFL16, body)
                                Swal2.fire('Notificado', 'Gracias por tu comentario', 'success');
                                console.log('Comentario ingresado:', comentario);
                            }
                        });
                    } else {
                        Swal("No se inserto el RADICADO del registro!", "Valide la información!", "warning");
                    }
                })
            }
        }
    };

    const handleComboboxChangeSinSection = (name) => (event) => {
        setFormData({
            ...formData,
            [name]: event.target.value,
        });
    };


    const validateImages = (formData) => {
        const missingImages = [];

        console.log(formData["vehiculo"]["carpado"])
        console.log(formData["tipoImportacion"]["importacion"])

        const images = formData["tipoImportacion"]["importacion"] == "IMPORTACION"
            ? formData.imagenesImportacion
            : formData.imagenesExportacion;
        var requiredImages = []

        if (formData["tipoImportacion"]["importacion"] == "IMPORTACION" &&
            ["carpado", "furgon"].includes(formData["vehiculo"]["carpado"]) == true) {
            requiredImages = [
                'fotoConductorPlaca',
                'fotoCabezotePlaca',
                'fotoSoatLicencia',
                'fotoSelloPrecinto',
                'fotoPiezasVehiculo',
                'fotoFuncionariosDescargue',
                'fotoInteriorVehiculoDescargado',
                'fotoProcesoDescargue',
                'fotoDestruccionPrecintos'
            ]
        } else if (formData["tipoImportacion"]["importacion"] == "EXPORTACION" &&
            ["carpado", "furgon"].includes(formData["vehiculo"]["carpado"]) == true) {
            requiredImages = [
                'fotoConductorPlaca',
                'fotoCabezotePlaca',
                'fotoInteriorContenedor',
                'fotoSoatLicencia',
                'fotoSelloPrecintoAntes',
                'fotoPiezasVehiculoAntes',
                'fotoFuncionariosCargue',
                'fotosProcesoCargue',
                'fotosVehiculoCerrado',
                'fotoNumeroSelloPrecintos',
            ]
        } else if (formData["tipoImportacion"]["importacion"] == "IMPORTACION" &&
            ["contenedor"].includes(formData["vehiculo"]["carpado"]) == true) {
            requiredImages = [
                'fotoConductorPlaca',
                'fotoCabezotePlaca',
                'fotoNumeroTrailer',
                'fotoSoatLicencia',
                'fotoSelloPrecinto',
                'fotoNumeroContenedor',
                'fotoPiezasVehiculo',
                'fotoFuncionariosDescargue',
                'fotoInteriorVehiculoDescargado',
                'fotoProcesoDescargue',
                'fotoDestruccionPrecintos'
            ]
        } else if (formData["tipoImportacion"]["importacion"] == "EXPORTACION" &&
            ["contenedor"].includes(formData["vehiculo"]["carpado"]) == true) {
            requiredImages = [
                'fotoConductorPlaca',
                'fotoCabezotePlaca',
                'fotoNumeroTrailer',
                'fotoContenedorVehiculo',
                'fotoInteriorContenedor',
                'fotoSoatLicencia',
                'fotoSelloPrecintoAntes',
                'fotoPiezasVehiculoAntes',
                'fotoFuncionariosCargue',
                'fotosProcesoCargue',
                'fotosVehiculoCerrado',
                'fotoNumeroSelloPrecintos',
                'registroFilmicoPerforacionGuacales',
                'destruccionPrecintos'
            ]
        } else {
            alert("NO esta funcionando el control de fotos")
        }

        requiredImages.forEach((image) => {
            if (!images[image]) {
                missingImages.push(image);
            }
        });

        console.log(missingImages)
        return missingImages;
    };

    const handleClearForm = () => {
        Swal({
            title: '¿Estás seguro?',
            text: "Esta acción eliminará todos los datos del formulario y del almacenamiento local.",
            icon: 'warning',
            buttons: ["Cancelar", "Sí, limpiar todo"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                // Limpiar el formulario
                setFormData(initialFormData);

                // Limpiar firmas
                if (sigPadConductor.current) sigPadConductor.current.clear();
                if (sigPadResponsableInspeccion.current) sigPadResponsableInspeccion.current.clear();

                // Eliminar datos del localStorage
                localStorage.removeItem('inspeccionFormularioData');

                Swal("Los datos han sido eliminados correctamente.", {
                    icon: "success",
                });
            }
        });
    };




    const handleSubmit = (event) => {

        Swal2.fire({
            title: 'Cargando...',
            text: 'Por favor espera',
            allowOutsideClick: false, // Para que no se cierre al hacer clic fuera
            didOpen: () => {
                Swal2.showLoading(); // Mostrar animación de carga
            },
        });

        event.preventDefault();
        const updatedFormData = {
            ...formData,
            ["Guardado"]: {
                ...formData["Guardado"],
                ["habilitado"]: event.target.value,
            },
        };
        setFormData(updatedFormData);
        const datos = JSON.stringify(updatedFormData).replace(/(\r\n|\n|\r)/gm, '');
        ModificarFL16(localStorage.RadicadoFL16, datos).then((result) => {
            if (result) {
                TraerDatosFL16(localStorage.RadicadoFL16).then((result) => {
                    //localStorage.getItem("inspeccionFormularioData");
                    const inspeccionFormularioData = JSON.parse(result);
                    const storedData = inspeccionFormularioData || {};
                    const firmaConductor = storedData.firmas?.firmaConductor;
                    const firmaResponsableInspeccion = storedData.firmas?.firmaResponsableInspeccion;
                    if (!firmaConductor) {
                        Swal("Error", "Firma del Conductor", "error");
                        Swal2.close();
                    }
                    if (!firmaResponsableInspeccion) {
                        Swal("Error", "Firma del Responsable de la Inspección", "error");
                        Swal2.close();
                    }
                    const missingImages = validateImages(formData);
                    if (missingImages.length > 0) {
                        Swal2.close();
                        Swal({
                            title: 'Faltan imágenes',
                            text: `Aún falta(s) imagen(es) por ingresar: ${missingImages.join(', ')}`,
                            icon: 'warning',
                            confirmButtonText: 'Aceptar'
                        });
                    } else {

                        var raw = JSON.stringify({
                            "datos": `${JSON.stringify(inspeccionFormularioData)}`
                        });
                        //console.log(firmaFile)
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };
                        //fetch("http://localhost:3001/api/talsa/pdf/v1/pdffl16", requestOptions)
                        fetch("https://consumos.talsa.com.co/api/talsa/pdf/v1/pdffl16", requestOptions)
                            .then(response => {
                                if (response.status == 200) {
                                    return response.text(); // Return the response text to the next then
                                } else {
                                    setLoading(false)
                                    Swal("Error", "El documento no fue creado con exito", "error");
                                    throw new Error('El documento no fue creado con exito');
                                }
                            })
                            .then(responseText => {
                                const responseJson = JSON.parse(responseText);
                                const base64 = responseJson.base64;
                                InsertFormFL16(localStorage.RadicadoFL16, base64).then((resultform) => {
                                    if (resultform) {
                                        Swal2.close();
                                        Swal2.fire({
                                            icon: 'success',
                                            title: 'Exito!',
                                            text: 'Ingreso la información con exito`, "GRACIAS!',
                                        });
                                    } else {
                                        Swal2.close();
                                        Swal2.fire({
                                            icon: 'error',
                                            title: 'Error',
                                            text: 'Ocurrió un error durante el proceso',
                                        });
                                    }
                                })
                                /*
                                                                // Crear un enlace de descarga
                                                                const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64}`;
                                                                const downloadLink = document.createElement("a");
                                                                downloadLink.href = linkSource;
                                                                downloadLink.download = "documento.docx";
                                
                                                                // Añadir el enlace al cuerpo del documento y hacer clic en él
                                                                document.body.appendChild(downloadLink);
                                                                downloadLink.click();
                                                                document.body.removeChild(downloadLink);
                                
                                                                // Mostrar un mensaje al usuario
                                                                Swal({
                                                                    title: 'Documento generado',
                                                                    text: 'El documento ha sido descargado. Para visualizarlo en línea, por favor súbalo a su Google Drive y ábralo desde allí.',
                                                                    icon: 'success',
                                                                    confirmButtonText: 'Aceptar'
                                                                });*/
                            })
                    }

                })

            } else {
                Swal("Los datos no estan modificando revisar con el area de sistemas", "warning");
            }
        })

    };


    function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    const handleCambioRadicado = (nuevoValor) => {
        setValorRadicado(nuevoValor); // Esto actualiza el estado y provoca un re-render
    };

    const handleImageUpload = (name) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    imagenesImportacion: {
                        ...prevFormData.imagenesImportacion,
                        [name]: base64String,
                    },
                }));
                // Restablecer el valor del input file para permitir la selección del mismo archivo
                event.target.value = '';
            };
            reader.readAsDataURL(file);
        }
    };



    const handleImageUpload2 = (name) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    imagenesExportacion: {
                        ...prevFormData.imagenesExportacion,
                        [name]: base64String,
                    },
                }));
                // Restablecer el valor del input file para permitir la selección del mismo archivo
                event.target.value = '';
            };
            reader.readAsDataURL(file);
        }
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    const responsiveGridSize = isMobile ? 12 : (isTablet ? 6 : 4);
    const responsiveGridSize2 = isMobile ? 12 : (isTablet ? 6 : 6);


    const renderTextField = (name, label, value) => (
        <TextField
            fullWidth
            required
            variant="outlined"
            name={name}
            label={label}
            value={value}
            onChange={handleChange}
            margin="dense"
            InputProps={{
                sx: {
                    fontSize: '0.9rem',
                }
            }}
        />
    );

    const renderTimeField = (name, label, value) => (
        <TextField
            fullWidth
            required
            variant="outlined"
            type="time"
            name={name}
            label={label}
            value={value}
            onChange={handleTimeChange}
            margin="dense"
            InputLabelProps={{
                shrink: true, // Permite que la etiqueta no se superponga al valor del tiempo
            }}
            InputProps={{
                sx: {
                    fontSize: '0.9rem',
                }
            }}
        />
    );


    const renderCheckbox = (section, name, label) => (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        name={name}
                        checked={formData[section][name]}
                        onChange={handleCheckboxChange(section, name)}
                    />
                }
                label={label}
                sx={{ marginLeft: 0 }} // Ajusta el margen si es necesario
            />
        </Grid>
    );

    const renderComboboxSin = (section, name, label) => (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControl fullWidth sx={{ minWidth: 100 }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    required
                    value={formData[section][name]}
                    onChange={handleComboboxChange(section, name)}
                    label={label}
                >
                    <MenuItem value="sí">Sí</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );

    const renderComboboxTIPO = (section, name, label) => (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControl fullWidth sx={{ minWidth: 100 }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    required
                    value={formData[section][name]}
                    onChange={handleComboboxChange(section, name)}
                    label={label}
                >
                    <MenuItem value="IMPORTACION">IMPORTACION</MenuItem>
                    <MenuItem value="EXPORTACION">EXPORTACION</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );

    const renderDateField = (name, label, value) => (
        <TextField
            fullWidth
            required
            variant="outlined"
            type="date"
            name={name}
            label={label}
            value={value}
            onChange={handleDateChange} // Usa handleDateChange para manejar los cambios
            margin="dense"
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                sx: {
                    fontSize: '0.9rem',
                }
            }}
        />
    );


    const renderCombobox = (section, name, label) => (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControl fullWidth sx={{ minWidth: 100 }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    required
                    value={formData[section][name]}
                    onChange={handleComboboxChange(section, name)}
                    label={label}
                >
                    <MenuItem value="sí">Sí</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                    <MenuItem value="na">N/A</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );


    const renderImageUpload = (name, label) => (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControl fullWidth sx={{ marginTop: 5 }}>
                <InputLabel shrink>{label}</InputLabel>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`upload-${name}`}
                    type="file"
                    onChange={handleImageUpload(name)}
                />
                <label htmlFor={`upload-${name}`}>
                    <Button variant="contained" component="span">
                        {formData.imagenesImportacion[name] ? 'Cambiar imagen' : 'Subir imagen'}
                    </Button>
                </label>
                {formData.imagenesImportacion[name] && (
                    <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                        Imagen cargada
                    </Typography>
                )}
            </FormControl>
        </Grid>
    );


    const renderImageUpload1 = (name, label) => (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <FormControl fullWidth sx={{ marginTop: 5 }}>
                <InputLabel shrink>{label}</InputLabel>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`upload-${name}`}
                    type="file"
                    onChange={handleImageUpload2(name)}
                />
                <label htmlFor={`upload-${name}`}>
                    <Button variant="contained" component="span">
                        {formData.imagenesExportacion[name] ? 'Cambiar imagen' : 'Subir imagen'}
                    </Button>
                </label>
                {formData.imagenesExportacion[name] && (
                    <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                        Imagen cargada
                    </Typography>
                )}
            </FormControl>
        </Grid>
    );

    return (
        <form onSubmit={handleSubmit}>
            <CambioRadicado onChange={handleCambioRadicado}></CambioRadicado>
            <Box sx={{ padding: '1rem', border: '2px solid #000' }}>

                <Grid item xs={12}>
                    <Box
                        sx={{
                            border: '2px solid #1976d2',
                            padding: '1.5rem',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            backgroundColor: '#e3f2fd',
                            height: '40%'
                        }}>
                        <Typography
                            variant="h5"
                            sx={{
                                borderBottom: '2px solid #1976d2',
                                paddingBottom: '0.5rem',
                                color: '#0d47a1',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}>
                            Radicado de Exp/Imp {localStorage.RadicadoFL16}
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                borderBottom: '2px solid #1976d2',
                                paddingBottom: '0.5rem',
                                color: '#0d47a1',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}>
                            1. Vehículo / Conductor
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={2}>
                    {/* Vehículo / Conductor */}
                    <Grid item xs={12} md={responsiveGridSize}>
                        <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                            <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>1.1 Vehículo / Conductor</Typography>
                            <Grid container spacing={2}>
                                {renderTextField("marcaContenedor", "Marca del contenedor", formData.marcaContenedor)}
                                {renderTextField("numeroContenedor", "N° Contenedor", formData.numeroContenedor)}

                                {renderTextField("placaVehiculo", "Placa del vehículo", formData.placaVehiculo)}

                                {renderTextField("numeroTrailer", "N° Trailer", formData.numeroTrailer)}


                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <FormControl fullWidth sx={{ minWidth: 100 }}>
                                        <InputLabel>{"tipo vehiculo"}</InputLabel>
                                        <Select
                                            required
                                            value={formData["vehiculo"]["carpado"]}
                                            onChange={handleComboboxChange("vehiculo", "carpado")}
                                            label={"tipo vehiculo"}
                                        >
                                            <MenuItem value="carpado">Carpado</MenuItem>
                                            <MenuItem value="contenedor">Contenedor</MenuItem>
                                            <MenuItem value="furgon">Furgón</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <FormControl fullWidth sx={{ minWidth: 100 }}>
                                        <InputLabel>{"empresaTransportadora"}</InputLabel>
                                        <Select
                                            required
                                            value={formData["empresaTransportadora"]}
                                            onChange={handleComboboxChangeSinSection("empresaTransportadora")}
                                            label={"empresaTransportadora"} >
                                            <MenuItem value="Transportadora Estrella S.A.S">Transportadora Estrella S.A.S</MenuItem>
                                            <MenuItem value="Logística S.A.S">Logística S.A.S</MenuItem>
                                            <MenuItem value="Intercargueros Andinos">Intercargueros Andinos</MenuItem>
                                            <MenuItem value="Tanques del Nordeste (soulgreen)">Tanques del Nordeste (soulgreen)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                {renderTextField("conductor", "Conductor", formData.conductor)}
                                {renderTextField("cedula", "Cédula", formData.cedula)}
                                {renderTextField("numeroCelular", "Número de celular", formData.numeroCelular)}
                                {renderDateField("fechaARL", "Fecha de ARL", formData.fechaARL)}


                            </Grid>
                        </Box>
                    </Grid>

                    {/* Vehículo y Seguridad */}
                    <Grid item xs={12} md={responsiveGridSize}>
                        <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                            <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>1.2 Vehículo</Typography>
                            <Grid container spacing={2}>
                                {renderComboboxSin('vehiculo', 'tarjetaPropiedad', "Tarjeta de propiedad (Matrícula)")}
                                {renderComboboxSin('vehiculo', 'soat', "SOAT")}
                                {renderComboboxSin('vehiculo', 'licenciaConduccion', "Licencia de conducción (VIGENTE)")}
                                {renderComboboxSin('vehiculo', 'manifiestoCarga', "Manifiesto de carga (Factura)")}
                            </Grid>
                            <Typography variant="h6" sx={{ borderBottom: '1px solid #000', margin: '1rem 0' }}>1.4 Seguridad (Equipo de carretera)</Typography>
                            <Grid container spacing={2}>
                                {renderComboboxSin('equipoCarretera', 'gato', "Gato con capacidad para levantar el vehículo")}
                                {renderComboboxSin('equipoCarretera', 'cruceta', "Cruceta")}
                                {renderComboboxSin('equipoCarretera', 'triangulos', "Señales de carretera (Triángulos)")}
                                {renderComboboxSin('equipoCarretera', 'botiquin', "Botiquín (Dotado y en buen estado)")}
                                {renderComboboxSin('equipoCarretera', 'tacos', "Tacos para bloquear el vehículo")}
                                {renderComboboxSin('equipoCarretera', 'extintor', "Extintor (es) 10Lb. Vigentes")}
                                {renderComboboxSin('equipoCarretera', 'linterna', "Linterna (Funcional)")}
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Inspección visual 360 */}
                    <Grid item xs={12} md={responsiveGridSize}>
                        <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                            <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>1.3 Inspección visual 360</Typography>
                            <Grid container spacing={2}>
                                {renderCombobox('inspeccionVisual', 'cabina', "Estado aceptable de cabina")}
                                {renderCombobox('inspeccionVisual', 'cajaHerramientas', "Caja de herramientas")}
                                {renderCombobox('inspeccionVisual', 'tanqueAgua', "Tanque de agua")}
                                {renderCombobox('inspeccionVisual', 'llantaRepuesto', "Llanta de repuesto")}
                                {renderCombobox('inspeccionVisual', 'pisoNivelado', "Piso nivelado")}
                                {renderCombobox('inspeccionVisual', 'puertasCompartimientos', "Puertas y compartimientos de herramientas")}
                                {renderCombobox('inspeccionVisual', 'cajaBateria', "Caja de la batería")}
                                {renderCombobox('inspeccionVisual', 'lateralesCarroceria', "Laterales y carrocería")}
                                {renderCombobox('inspeccionVisual', 'compartimientosInterior', "Compartimientos del interior de la cabina y dormitorio")}
                                {renderCombobox('inspeccionVisual', 'chasisQuintaRueda', "Chasis y área de la quinta rueda")}
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Espacio reservado */}
                    <Grid item xs={12}>
                        <Box sx={{ border: '1px solid #000', padding: '1rem', textAlign: 'center' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                                Espacio reservado para TECNOLOGÍA ALIMENTARIA S.A.S BIC
                            </Typography>

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <FormControl fullWidth sx={{ minWidth: 100 }}>
                                    <InputLabel>{"Los datos coinciden con los informados por el proveedor de transporte"}</InputLabel>
                                    <Select
                                        required
                                        disabled={formData["inspeccionVisual"]["datosCoinciden"] == "no" ? true : false}
                                        value={formData["inspeccionVisual"]["datosCoinciden"]}
                                        onChange={handleComboboxChangeEspecial("inspeccionVisual", "datosCoinciden", "tipo1")}
                                        label={"Los datos coinciden con los informados por el proveedor de transporte"}>
                                        <MenuItem value="sí">Sí</MenuItem>
                                        <MenuItem value="no">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Condiciones físico sanitarias */}

                    {formData["inspeccionVisual"]["datosCoinciden"] == "no" ? <>
                    </> :
                        <>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        border: '2px solid #1976d2',
                                        padding: '1.5rem',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#e3f2fd',
                                        height: '40%'
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            borderBottom: '2px solid #1976d2',
                                            paddingBottom: '0.5rem',
                                            color: '#0d47a1',
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}
                                    >
                                        2. Condiciones físico sanitarias (Vehículo / Conductor)
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Placeholder boxes for future content */}


                            <Grid item xs={12} md={responsiveGridSize2}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>2.1 Condiciones sanitarias
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderComboboxSin('inspeccionCondiciones', 'plagas', 'Libre de plagas vivas o muertas (roedores, pájaros, insectos)')}
                                        {renderComboboxSin('inspeccionCondiciones', 'materialExtrano', 'Libre de material extraño (pelos, plumas, polvo, vidrio, metal) y líquidos')}
                                        {renderComboboxSin('inspeccionCondiciones', 'humedadGrasa', 'Libre de humedad, grasa u olores fuertes o pestilentes')}
                                        {renderComboboxSin('inspeccionCondiciones', 'materialesDiferentes', 'Vehículo no transporta materiales diferentes a la naturaleza del producto')}
                                        {renderComboboxSin('inspeccionCondiciones', 'compartimientoConduccion', 'Compartimiento de conducción limpio y en buenas condiciones higiénicas')}
                                        {renderComboboxSin('inspeccionCondiciones', 'compartimientoCarga', 'Compartimiento de carga limpio y en buenas condiciones higiénicas')}
                                        {renderComboboxSin('inspeccionCondiciones', 'normasPlanta', 'El personal cumple y conoce las normas para ingresar a la planta')}
                                        {renderComboboxSin('inspeccionCondiciones', 'presentacionPersonal', 'La presentación personal del conductor es adecuada')}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={responsiveGridSize2}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>2.2 Condiciones físicas
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderComboboxSin('inspeccionCondiciones', 'estadoVehiculo', 'Vehículo en buen estado (sin grietas, agujeros u otras averías)')}
                                        {renderComboboxSin('inspeccionCondiciones', 'bisagras', 'Bisagras de puertas en buen estado')}
                                        {renderComboboxSin('inspeccionCondiciones', 'ausenciaPisos', 'Ausencia de pisos, paredes y techos falsos')}
                                        {renderComboboxSin('inspeccionCondiciones', 'ausenciaRemaches', 'Ausencia de remaches nuevos y/o soldaduras recientes')}
                                        {renderTextField("observaciones22", "Observaciones (describa cuáles):", formData.observaciones22)}
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <FormControl fullWidth sx={{ minWidth: 100 }}>
                                                <InputLabel>{"Hago constar que el vehículo es apto para el transporte de mercancía (Nombre legible):"}</InputLabel>
                                                <Select
                                                    required
                                                    value={formData["constanciatransporte"]}
                                                    onChange={handleComboboxChangeSinSection("constanciatransporte")}
                                                    label={"constanciatransporte"} >
                                                    <MenuItem value="Néstor Arias">Néstor Arias</MenuItem>
                                                    <MenuItem value="Wilson Cortez">Wilson Cortez</MenuItem>
                                                    <MenuItem value="Jhonatan González">Jhonatan González</MenuItem>
                                                    <MenuItem value="Luciano Cardona">Luciano Cardona</MenuItem>
                                                    <MenuItem value="Alexander Mindiola">Alexander Mindiola</MenuItem>
                                                    <MenuItem value="Julián Suarez">Julián Suarez</MenuItem>
                                                    <MenuItem value="Marco Ávila">Marco Ávila</MenuItem>
                                                    <MenuItem value="Alejandro Moncada">Alejandro Moncada</MenuItem>
                                                    <MenuItem value="Alfredo Barroso">Alfredo Barroso</MenuItem>
                                                    <MenuItem value="Juan Higuita">Juan Higuita</MenuItem>
                                                    <MenuItem value="Pablo Oquendo">Pablo Oquendo</MenuItem>
                                                    <MenuItem value="Leonardo García">Leonardo García</MenuItem>
                                                    <MenuItem value="José Muñoz">José Muñoz</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        border: '2px solid #1976d2',
                                        padding: '1.5rem',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#e3f2fd',
                                        height: '40%'
                                    }}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            borderBottom: '2px solid #1976d2',
                                            paddingBottom: '0.5rem',
                                            color: '#0d47a1',
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}>
                                        3. Espacio reservado para TECNOLOGÍA ALIMENTARIA S.A.S BIC
                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={12}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.1 PUERTAS
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderTextField("PaisOrigen", "País de origen/destino:", formData.PaisOrigen)}
                                        {renderTextField("operacion", "# de Operación:", formData.operacion)}
                                        {renderTimeField("horaLlegada", "Hora de Llegada", formData.horaLlegada)}
                                        {renderComboboxSin('inspeccionCondiciones31', 'cargaSolida', 'Carga consolidada')}
                                        <FormControl fullWidth sx={{ minWidth: 100 }}>
                                            <InputLabel>{"Transporte Principal"}</InputLabel>
                                            <Select
                                                required
                                                value={formData["inspeccionCondiciones31"]["transport"]}
                                                onChange={handleComboboxChange("inspeccionCondiciones31", "transport")}
                                                label={"Transporte Principal"}>
                                                <MenuItem value="Marítimo">Marítimo</MenuItem>
                                                <MenuItem value="Aéreo">Aéreo</MenuItem>
                                                <MenuItem value="Terrestre">Terrestre</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={responsiveGridSize}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.1 PUERTAS
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderCombobox('inspeccionCondiciones31', 'numeroContenedor', '¿El número del contenedor es diferente en uno o más de los 6 lados?')}
                                        {renderCombobox('inspeccionCondiciones31', 'adhesivoNuevo', '¿Adhesivo o pegante nuevo en uniones de las láminas?')}
                                        {renderCombobox('inspeccionCondiciones31', 'marcasSoldadura', '¿Marcas o quemaduras recientes de soldadura?')}
                                        {renderCombobox('inspeccionCondiciones31', 'pinturaNueva', '¿Pintura nueva en partes o parches?')}
                                        {renderCombobox('inspeccionCondiciones31', 'ondulaciones', '¿Ondulaciones internas y externas desiguales en tamaño y altura?')}
                                        {renderCombobox('inspeccionCondiciones31', 'vigasSonido', '¿Vigas y travesaños con sonido metálico disparejo? (diferente en algún punto de su longitud)')}
                                        {renderCombobox('inspeccionCondiciones31', 'canalesTapas', '¿Canales superiores e inferiores internos con tapas?')}
                                        {renderCombobox('inspeccionCondiciones31', 'remachesTuercas', '¿Remaches y tuercas de los seguros de las manijas ocultos y soldados de la parte interna?')}
                                        {renderCombobox('inspeccionCondiciones31', 'areasGolpes', '¿Áreas aledañas a remaches o bisagras con muestra de golpes, pintura nueva o forcejeo?')}
                                        {renderCombobox('inspeccionCondiciones31', 'olores', '¿Olores a pintura, soldadura, madera quemada, pegante, materiales de relleno, grasa, etc.?')}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={responsiveGridSize}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.2 TECHO
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderCombobox('inspeccionCondiciones32', 'soportesOcultos', '¿Los soportes (vigas superiores) se encuentran ocultos (no visibles)?')}
                                        {renderCombobox('inspeccionCondiciones32', 'orificiosVentilacion', '¿Orificios de ventilación se encuentran fuera de estándares (de 50 a 60 cm de las vigas esquineras y a 5 cm de los dos travesaños laterales superiores)?')}
                                        {renderCombobox('inspeccionCondiciones32', 'techoDesnivelado', '¿Techo desnivelado?')}
                                        {renderCombobox('inspeccionCondiciones32', 'marcasSoldaduraTecho', '¿Marcas o quemaduras recientes de soldadura en el techo?')}
                                        {renderCombobox('inspeccionCondiciones32', 'pinturaNuevaTecho', '¿Pintura nueva en partes o parches en el techo?')}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={responsiveGridSize}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.3 COSTADO
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderCombobox('inspeccionCondiciones33', 'marcasSoldadura', '¿Marcas o quemaduras recientes de soldadura?')}
                                        {renderCombobox('inspeccionCondiciones33', 'pinturaNueva', '¿Pintura nueva en partes o parches?')}
                                        {renderCombobox('inspeccionCondiciones33', 'sonidoOndulaciones', '¿Sonido sólido en ondulaciones y láminas?')}
                                        {renderCombobox('inspeccionCondiciones33', 'longitudInterna', '¿Al medir la longitud interna se encuentra fuera de estándares normales (5.90 mt contenedor 20´´ y 12.03 mt contenedor de 40´´)?')}
                                    </Grid>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={responsiveGridSize}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.4 PISO
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderCombobox('inspeccionCondiciones34', 'desnivelado', '¿Está desnivelado?')}
                                        {renderCombobox('inspeccionCondiciones34', 'nivelVigasInferiores', '¿Se encuentra por encima del nivel de las vigas inferiores?')}
                                        {renderCombobox('inspeccionCondiciones34', 'reparacionesMalosAcabados', '¿Reparaciones nuevas con malos acabados?')}
                                        {renderCombobox('inspeccionCondiciones34', 'pisoExteriorVigas', '¿Piso exterior con vigas diferentes a formas de I, L, T?')}
                                        {renderCombobox('inspeccionCondiciones34', 'tunelPisoExterior', '¿El túnel del piso exterior muestra cambios o diferencias? (para cont. 40")')}
                                        {renderCombobox('inspeccionCondiciones34', 'cambiosChapasyTravesanos', '¿Piso exterior muestra cambios o diferencias en chapas esquineras, laterales inferiores y travesaños?')}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={responsiveGridSize}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.5  Cantidades
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderTextField("unidadesRecibidas", "Unidades recibidas ", formData.unidadesRecibidas)}
                                        {renderCombobox('cantidades', 'coincidenCantidades', '¿Unidades recibidas cumplen')}
                                        {renderTextField("peso35", "Peso", formData.peso35)}

                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <FormControl fullWidth sx={{ minWidth: 100 }}>
                                                <InputLabel>{"¿El peso coinciden?"}</InputLabel>
                                                <Select
                                                    required
                                                    value={formData["cantidades"]["coincidenPeso"]}
                                                    onChange={handleComboboxChangeEspecial("cantidades", "coincidenPeso", "tipo2")}
                                                    label={"¿El peso coinciden?"}
                                                >
                                                    <MenuItem value="sí">Sí</MenuItem>
                                                    <MenuItem value="no">No</MenuItem>
                                                    <MenuItem value="na">N/A</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <FormControl fullWidth sx={{ minWidth: 100 }}>
                                                <InputLabel>{"¿Las cantidades coinciden?"}</InputLabel>
                                                <Select
                                                    required
                                                    value={formData["cantidades"]["coincidenCantidadesv2"]}
                                                    onChange={handleComboboxChangeEspecial("cantidades", "coincidenCantidadesv2", "tipo2")}
                                                    label={"¿Las cantidades coinciden?"}>
                                                    <MenuItem value="sí">Sí</MenuItem>
                                                    <MenuItem value="no">No</MenuItem>
                                                    <MenuItem value="na">N/A</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {renderTextField("observaciones35", "Observaciones:", formData.observaciones35)}

                                        <Box
                                            sx={{
                                                border: '2px solid #1976d2',
                                                padding: '1.5rem',
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                backgroundColor: '#e3f2fd',
                                                height: '40%'
                                            }}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    borderBottom: '2px solid #1976d2',
                                                    paddingBottom: '0.5rem',
                                                    color: '#0d47a1',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}> Personal de Tecnología Alimentaria S.A.S BIC involucrado en el despacho / recepción
                                            </Typography>
                                        </Box>
                                        {renderTextField("Personal1", "Personal de TALSA", formData.Personal1)}
                                        {renderTextField("Personal2", "Personal de TALSA", formData.Personal2)}
                                        {renderTextField("Personal3", "Personal de TALSA", formData.Personal3)}
                                        {renderTextField("Personal4", "Personal de TALSA", formData.Personal4)}
                                        {renderTextField("Personal5", "Personal de TALSA", formData.Personal5)}

                                    </Grid>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={responsiveGridSize}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.6 Condiciones del material de empaque
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderComboboxSin('inspeccionCondiciones36', 'alegatoriaDeCajas', 'Verificación aleatoria de las cajas (Cuando aplique)')}
                                        {renderComboboxSin('inspeccionCondiciones36', 'alegatoriaDeGuacal', 'Verificación aleatoria del guacal (Cuando aplique)')}
                                        {renderTextField("observaciones36", "Observaciones:", formData.observaciones36)}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>4. Condiciones al momento de recepción/ despacho
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {renderComboboxSin('inspeccionCondiciones4', 'hermeticas', 'Hermeticidad de las puertas')}
                                        {renderComboboxSin('inspeccionCondiciones4', 'sellos', 'Sellos correspondientes bien instalados y asegurados')}
                                        {renderTextField("identificacion", "# Identificación de sellos:", formData.identificacion)}
                                        {renderComboboxSin('inspeccionCondiciones4', 'selloproveedor', 'Sello coincide con el instalado por el proveedor')}
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        border: '2px solid #1976d2',
                                        padding: '1.5rem',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        backgroundColor: '#e3f2fd',
                                        height: '40%'
                                    }}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            borderBottom: '2px solid #1976d2',
                                            paddingBottom: '0.5rem',
                                            color: '#0d47a1',
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}>
                                        5.Fotos (Importación / Exportación) Seleccione
                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={12}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Grid container spacing={2}>
                                        {renderComboboxTIPO('tipoImportacion', 'importacion', 'TIPO DE IMPORTACIÓN')}
                                    </Grid>
                                </Box>
                            </Grid>

                            {formData["tipoImportacion"]["importacion"] == "IMPORTACION" ?
                                <Grid item xs={12} md={12}>
                                    <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                        <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>IMPORTACION
                                        </Typography>
                                        <Grid container spacing={2}>
                                            {renderImageUpload('fotoConductorPlaca', 'Foto del conductor al lado de la placa del vehículo (Sin tapabocas)')}
                                            {renderImageUpload('fotoCabezotePlaca', 'Foto del cabezote del vehículo donde se vea la placa')}
                                            {renderImageUpload('fotoNumeroTrailer', 'Foto del N° del tráiler')}
                                            {renderImageUpload('fotoSoatLicencia', 'Foto del SOAT y de la licencia de conducción')}
                                            {renderImageUpload('fotoSelloPrecinto', 'Foto del sello / Precinto antes de abrir contenedor o vehículo')}
                                            {renderImageUpload('fotoNumeroContenedor', 'Foto donde se vea el N° del contenedor')}
                                            {renderImageUpload('fotoPiezasVehiculo', 'Foto de las piezas en el vehículo antes de descargar')}
                                            {renderImageUpload('fotoFuncionariosDescargue', 'Foto de los funcionarios de CI Talsa que hacen la labor de descargue (Con chaleco puesto)')}
                                            {renderImageUpload('fotoInteriorVehiculoDescargado', 'Fotos del interior del vehículo descargado')}
                                            {renderImageUpload('fotoProcesoDescargue', 'Fotos del proceso de descargue')}
                                            {renderImageUpload('fotoDestruccionPrecintos', 'Destrucción de precintos')}
                                        </Grid>
                                    </Box>
                                </Grid>
                                : <Grid item xs={12} md={12}>
                                    <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                        <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>EXPORTACION
                                        </Typography>
                                        <Grid container spacing={2}>
                                            {renderImageUpload1('fotoConductorPlaca', 'Foto del conductor al lado de la placa del vehículo (Sin tapabocas)')}
                                            {renderImageUpload1('fotoCabezotePlaca', 'Foto del cabezote del vehículo donde se vea la placa')}
                                            {renderImageUpload1('fotoNumeroTrailer', 'Foto del N° del tráiler')}
                                            {renderImageUpload1('fotoContenedorVehiculo', 'Foto del contenedor / vehículo (4 lados exteriores)')}
                                            {renderImageUpload1('fotoInteriorContenedor', 'Fotos del interior del contenedor (Puertas / Techo / Costado / Piso)')}
                                            {renderImageUpload1('fotoSoatLicencia', 'Foto del SOAT y de la licencia de conducción')}
                                            {renderImageUpload1('fotoSelloPrecintoAntes', 'Foto del sello / Precinto de seguridad antes de instalar')}
                                            {renderImageUpload1('fotoPiezasVehiculoAntes', 'Foto de las piezas en el vehículo antes de cargar')}
                                            {renderImageUpload1('fotoFuncionariosCargue', 'Foto de los funcionarios de CI Talsa que hacen la labor de cargue (Con chaleco puesto)')}
                                            {renderImageUpload1('fotosProcesoCargue', 'Fotos del proceso de cargue')}
                                            {renderImageUpload1('fotosVehiculoCerrado', 'Fotos del vehículo / contenedor cerrado con sellos / precintos instalados')}
                                            {renderImageUpload1('fotoNumeroSelloPrecintos', 'Foto legible donde se vean N° de sello / precintos instalados')}
                                            {renderImageUpload1('registroFilmicoPerforacionGuacales', 'Registro fílmico de perforación de guacales')}
                                            {renderImageUpload1('destruccionPrecintos', 'Destrucción de precintos')}
                                        </Grid>
                                    </Box>
                                </Grid>

                            }

                            <Grid item xs={12} md={12}>
                                <Box sx={{ border: '1px solid #000', padding: '1rem', height: '100%' }}>
                                    <Typography variant="h6" sx={{ borderBottom: '1px solid #000', marginBottom: '1rem' }}>3.5  Cantidades
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                border: '2px solid #1976d2',
                                                padding: '1.5rem',
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                backgroundColor: '#e3f2fd',
                                                height: '40%'
                                            }}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    borderBottom: '2px solid #1976d2',
                                                    paddingBottom: '0.5rem',
                                                    color: '#0d47a1',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}>Novedades (describa en este espacio cualquier novedad o acción correctiva)
                                            </Typography>
                                        </Box>
                                        {renderTextField("novedades", "Novedades", formData.novedades)}

                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: "justify" }}>
                                <strong>Por favor recuerde firmar, guardar y enviar el formulario</strong>
                                <br />

                                {/* Firma del Conductor */}
                                <div>
                                    <p>Nombre y firma del conductor</p>
                                    <ReactSignatureCanvas
                                        canvasProps={{
                                            width: 550,
                                            height: 150,
                                            style: { border: "1px solid #000000" }
                                        }}
                                        minWidth={1}
                                        maxWidth={1}
                                        penColor="black"
                                        ref={sigPadConductor}
                                    />
                                    <br />
                                    <Button
                                        onClick={() => saveFirma(sigPadConductor, "firmaConductor")}
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: "10px" }}
                                    >
                                        Guardar Firma Conductor
                                    </Button>
                                    <Button
                                        onClick={() => sigPadConductor.current.clear()}
                                        variant="contained"
                                        color="secondary"
                                        style={{ margin: "10px" }}
                                    >
                                        Limpiar
                                    </Button>
                                </div>

                                {/* Firma del Responsable de la Inspección */}
                                <div>
                                    <p>Firma del responsable de la inspección / Cargo / Número de identificación</p>
                                    <ReactSignatureCanvas
                                        canvasProps={{
                                            width: 550,
                                            height: 150,
                                            style: { border: "1px solid #000000" }
                                        }}
                                        minWidth={1}
                                        maxWidth={1}
                                        penColor="black"
                                        ref={sigPadResponsableInspeccion}
                                    />
                                    <br />
                                    <Button
                                        onClick={() => saveFirma(sigPadResponsableInspeccion, "firmaResponsableInspeccion")}
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: "10px" }}
                                    >
                                        Guardar Firma Responsable
                                    </Button>
                                    <Button
                                        onClick={() => sigPadResponsableInspeccion.current.clear()}
                                        variant="contained"
                                        color="secondary"
                                        style={{ margin: "10px" }}
                                    >
                                        Limpiar
                                    </Button>
                                </div>
                            </Grid>
                        </>
                    }



                    <Grid item xs={12} md={12}>
                        <Box sx={{ padding: '2rem', height: '100%' }}>
                            <Grid item xs={12}>
                                <Button onClick={handleSave} variant="contained" color="secondary" startIcon={<SaveIcon />}>
                                    Guardar datos en memoria
                                </Button>
                                <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
                                    Guardar
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>


                </Grid >
            </Box >
        </form >
    );
};

export default InspeccionFormulario;